import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent';
import ContactComponent from '../contact/ContactComponent';
import CareerCarousel from '../career/CareerCarousel';
import { Link } from 'react-scroll';
import CardComponent from '../casestudies/CardComponent';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for styling
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { HiLightBulb } from "react-icons/hi";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdManageAccounts, MdCampaign } from "react-icons/md";
import { TbCirclesRelation } from "react-icons/tb";
import { FaHandsHelping } from "react-icons/fa";
import { TbWorldCheck } from "react-icons/tb";
import { SiQuantconnect } from "react-icons/si";

const Home = () => {
    const [vpParkAvenue, setVpParkAvenue] = useState(false)
    const [vdoAi, setVdoAi] = useState(false)
    const [zee5, setZee5] = useState(false)

    const buttonStyle = {
        width: '100%',
        mt: '20px',
        background: '#031927',
        '&:hover': {
            background: '#F4650E', // Set the same color as the normal state
        },
    };

    const NetworkbuttonStyle = {
        width: '100%',
        mt: '40px',
        py: '10px',
        color: 'white',
        '&:hover': {
            color: 'white',
            border: '1px solid white'
        },
        border: '1px solid white'
    }

    useEffect(() => {
        AOS.init({
            duration: 500, // Animation duration in milliseconds
            delay: 50,   // Delay between elements
        });
    }, []);

    return (
        <div id='hero' className='w-full relative overflow-hidden'>

            {/* <Link to='hero' spy={true} smooth={true} offset={-100} duration={200} >
                <div className='z-40 fixed top-[82%] right-5 w-10 h-10 rounded-full cursor-pointer flex items-center justify-center hover:scale-110 duration-100 bg-[#F4650E] hover:bg-[#F4650E]'>
                    <ArrowUpwardIcon fontSize='medium' className='text-white' />
                </div>
            </Link> */}

            <div className='video-container w-full h-[60vh] relative bg-black flex flex-col items-center'>
                <video autoPlay muted loop playsInline preload="auto" className='opacity-60 bg-black'>
                    <source src="/hero.mp4" type="video/mp4" />
                </video>

                <h1 className='text-5xl md:text-6xl w-[80%] text-center mt-32 font-bold z-10 text-white'>
                    BuzzMagnet: Elevate Your Brand's Narrative
                </h1>
            </div>

            <div className='w-full flex justify-center'>
                <div className='w-[95%] mt-5 md:mt-10'>
                    <h2 className='text-3xl text-center md:text-left md:text-5xl text-gray-600 font-bold'>Powerful PR <span className='redColor'>Transforming Brands Online</span></h2>
                    <p className='text-lg md:text-xl text-center md:text-left font-medium py-2 md:w-[50%]'>
                        Elevate your brand with our strategic PR solutions for impactful online presence.
                    </p>
                </div>
            </div>

            <div id='our-expertise' className='w-full flex flex-col items-center mt-20'>
                <div className='w-[95%] flex justify-between'>
                    <div className='w-[50%] h-[650px] hidden md:block'>
                        <img className='w-[100%] h-[100%] object-cover' src='/pr_team.jpg' alt='pr-team' />
                    </div>
                    <div className='md:w-[48%] flex flex-col justify-center px-3'>
                        <div data-aos="fade-left">
                            <h2 className='text-4xl pb-5 md:pb-0 md:text-base font-bold text-gray-600 text-center md:text-left'>Why BuzzMagnet?</h2>
                            <h3 className='font-bold text-xl md:text-2xl py-3 redColor'>Strategic Brilliance</h3>
                            <p className='text-gray-600'>Navigate the media landscape with precision. Our seasoned team crafts bespoke PR strategies, ensuring your brand message not only reaches but resonates with your target audience.</p>
                        </div>
                        <div data-aos="fade-left" className='mt-5'>
                            <h3 className='font-bold text-xl md:text-2xl py-3 redColor'>Media Alchemy</h3>
                            <p className='text-gray-600'>Transforming stories into headlines is our forte. BuzzMagnet has the Midas touch to convert your narratives into compelling news stories, securing your brand's place in the spotlight.</p>
                        </div>
                        <div data-aos="fade-left" className='mt-5'>
                            <h3 className='font-bold text-xl md:text-2xl py-3 redColor'>Brand Amplification</h3>
                            <p className='text-gray-600'>We don't just share your story; we amplify it. BuzzMagnet employs a multi-faceted approach, leveraging traditional media, digital platforms, and influencers to maximize your brand's visibility.</p>
                        </div>
                        <div data-aos="fade-left" className='mt-5'>
                            <h3 className='font-bold text-xl md:text-2xl py-3 redColor'>Tailored Solutions</h3>
                            <p className='text-gray-600'>Every brand is unique, and so are our strategies. BuzzMagnet customizes PR solutions, aligning them seamlessly with your brand identity and objectives, ensuring maximum impact.</p>
                        </div>
                    </div>
                </div>

                <div className='w-[95%] mt-20'>
                    <h1 className='text-center md:text-left font-bold text-3xl md:text-6xl py-5 redColor' data-aos="fade-up">What BuzzMagnet Offers</h1>
                    <p className='md:w-[70%] text-xl text-center md:text-left text-gray-600' data-aos="fade-up">At BuzzMagnet, we don't just generate buzz; we create magnetic resonance. Join us on the journey to redefine your brand's narrative and captivate the world. BuzzMagnet - Where Every Story Sparks a Movement.</p>
                    <div className='w-full mt-10 flex-wrap md:flex-nowrap flex justify-between gap-5' data-aos="fade-left">
                        <div className='w-full border px-6 py-4 rounded-xl shadow-md hover:shadow-2xl'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Media Relations</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>Cultivate lasting relationships with key media outlets, ensuring your brand stays top-of-mind in the ever-evolving media landscape.</p>
                        </div>
                        <div className='w-full border px-6 py-4 rounded-xl shadow-md hover:shadow-2xl'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Digital Presence</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>Harness the power of the digital realm with our comprehensive digital PR strategies, optimizing your brand's online visibility and engagement.</p>
                        </div>
                        <div className='w-full border px-6 py-4 rounded-xl shadow-md hover:shadow-2xl'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Crisis Management</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>Anticipate and navigate potential crises with grace. BuzzMagnet offers proactive crisis management to safeguard your brand's reputation.</p>
                        </div>
                        <div className='w-full border px-6 py-4 rounded-xl shadow-md hover:shadow-2xl'>

                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Influencer Collaboration</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>Connect with influencers who matter. We facilitate meaningful collaborations that authentically align with your brand, amplifying your message to a broader audience.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full py-10 flex justify-center items-center mt-20 bg-[#031927]'>
                <div className='w-[95%] flex flex-wrap md:flex-nowrap justify-between'>
                    <div className='md:w-full flex items-center'>
                        <div className='md:pr-5'>
                            <h1 className='font-medium text-center md:text-left text-3xl md:text-6xl py-5 text-white' data-aos="fade-up">BuzzMagnet: PR Excellence Unleashed</h1>
                            <p className='text-center md:text-left md:text-2xl text-white' data-aos="fade-up">
                                At BuzzMagnet, we transcend conventional public relations, propelling your brand into the spotlight with strategic expertise and unwavering passion. As a leading PR agency, we understand that your brand's story is more than just information—it's an experience waiting to captivate and resonate.
                            </p>

                            <div className='md:w-[200px] mb-10 md:mb-0' data-aos='fade-left'>
                                <Button variant="outlined" sx={NetworkbuttonStyle} >
                                    <Link to='contact' spy={true} smooth={true} offset={-30} duration={200} >Get Started</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='md:w-full'>
                        {/* <video autoPlay muted loop playsInline preload="auto" className='opacity-60 bg-black'>
                            <source src="/globe1.mp4" type="video/mp4" />
                        </video> */}
                        <img src='/image1.jpeg' alt='img' />
                    </div>
                </div>
            </div>

            {/* <div className='w-full flex flex-col items-center'>
                <div className='w-[95%] py-10 md:pt-20'>
                    <h1 className='redColor text-3xl text-center md:text-5xl font-bold' data-aos='fade-up'>Case Studies</h1>
                    <div className='w-full flex justify-center flex-wrap md:flex-nowrap gap-5 mt-10' data-aos='fade-left'>
                        <CardComponent link='https://www.socialsamosa.com/2021/10/viral-pitch-influencer-marketing-mandate-park-avenue/' />
                        <CardComponent link='https://www.medianews4u.com/vdo-ai-enters-in-to-partnership-with-zone-media/' />
                        <CardComponent link='https://www.adgully.com/viral-pitch-s-sumit-gupta-on-driving-innovation-and-growth-in-influencer-marketing-132966.html' />
                        <CardComponent link='https://www.adgully.com/zee5-partners-with-apex-media-136362.html' />
                    </div>
                </div>
            </div> */}

            <div className='w-full flex flex-col items-center'>
                <div className='w-[95%] py-10 md:pt-20'>
                    <h2 className='redColor text-3xl text-center md:text-5xl font-bold'>Key <span className='text-[#031927]'>Services</span> & Offerings</h2>
                    <div className='w-full flex flex-wrap md:flex-nowrap mt-10 justify-center'>
                        <div className='w-full text-right border-r-4 border-r-[#F4650E] pr-5 rounded-2xl text-xl md:text-3xl font-medium flex flex-col items-end gap-5'>
                            <p className='flex gap-2 items-center text-right'>Crisis Management<MdCampaign className='text-6xl redColor' /></p>
                            <p className='flex gap-2 items-center'>Event Amplification<HiLightBulb className='text-6xl redColor' /></p>
                            <p className='flex gap-2 items-center'>Content Creation Craft<MdManageAccounts className='text-6xl redColor' /></p>
                            <p className='flex gap-2 items-center'>Brand Positioning Power<FaPeopleGroup className='text-6xl redColor' /></p>
                        </div>
                        <div>
                            <img className='w-[100%] h-[100%] object-cover' src='mic.png' alt='mic' />
                        </div>
                        <div className='w-full text-xl md:text-3xl border-l-4 border-l-[#F4650E] pl-5 rounded-2xl font-medium flex flex-col gap-5'>
                            <p className='flex gap-2 items-center'><FaHandsHelping className='text-6xl redColor' />Digital PR Dynamo</p>
                            <p className='flex gap-2 items-center'><TbWorldCheck className='text-6xl redColor' />Strategic Storytelling</p>
                            <p className='flex gap-2 items-center'><TbCirclesRelation className='text-6xl redColor' />Media Relations Mastery</p>
                            <p className='flex gap-2 items-center'><SiQuantconnect className='text-6xl redColor' />Influencer Engagement Elevation</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id='services' className='w-full flex flex-col items-center'>
                <div className='w-[95%] py-10 md:py-20'>
                    <h1 className='redColor text-3xl text-center md:text-5xl font-bold' data-aos='fade-up'>Our Services</h1>

                    <div className='w-full mt-10 flex-wrap md:flex-nowrap flex justify-between gap-10 md:gap-5' data-aos='fade-left'>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Digital PR Dynamo</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Transform your digital presence with our Digital PR Dynamo service. From social media strategies to online reputation management, we navigate the digital realm, positioning your brand at the forefront of the online conversation.
                            </p>
                        </div>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Strategic Storytelling</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Craft compelling narratives with our Strategic Storytelling service. We go beyond mere communication, shaping your brand's story to resonate authentically with your target audience, creating lasting emotional connections.
                            </p>
                        </div>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Crisis Management</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                In times of crisis, maintain open communication channels, addressing concerns transparently. Swiftly adapt strategies, prioritize empathy, and reassure stakeholders, fostering trust amid uncertainties.
                            </p>
                        </div>


                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Content Creation Craft</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Fuel your PR strategy with our Content Creation Craft. From press releases to thought leadership articles, we produce compelling content that communicates your brand's narrative effectively across various platforms, enhancing visibility and influence.
                            </p>
                        </div>
                    </div>

                    <div className='w-full mt-10 flex-wrap md:flex-nowrap flex justify-between gap-10 md:gap-5' data-aos='fade-left'>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Media Relations Mastery</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Forge meaningful connections with the media landscape through our Media Relations Mastery. We secure impactful coverage, ensuring your brand's story reaches the right audience, driving engagement and enhancing credibility.
                            </p>
                        </div>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Influencer Engagement Elevation</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Elevate your brand through strategic Influencer Engagement. We identify and collaborate with influencers whose ethos aligns seamlessly with yours, amplifying your message and extending your reach to diverse and engaged audiences.
                            </p>
                        </div>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Event Amplification</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Make your events unforgettable with our Event Amplification service. Whether it's a product launch, corporate gathering, or industry conference, we ensure your events generate buzz, leaving a lasting impression on attendees and the media.
                            </p>
                        </div>
                        <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 hover:border-b-[#031927] hover:shadow-2xl hover:border-b-4 border-b-[#F4650E]'>
                            <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Brand Positioning Power</h2>
                            <p className='pt-2 text-center md:text-start text-gray-600'>
                                Establish a strong market presence with our Brand Positioning Power service. We analyze market trends, competitor landscapes, and consumer behavior to position your brand strategically, ensuring you stand out in a crowded marketplace.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='w-[95%] flex flex-wrap md:flex-nowrap justify-between mt-10'>
                    <div className='w-full md:py-10'>
                        <h1 className='redColor text-center md:text-start text-3xl md:text-5xl font-bold' data-aos='fade-up'>BuzzMagnet&apos;s Diverse Brilliance</h1>
                        <p className='pt-5 md:w-[80%] text-xl font-medium text-center md:text-start text-gray-600' data-aos='fade-up'>
                            At BuzzMagnet, our diverse array of services is designed to propel your brand to new heights. Each service is meticulously crafted to deliver tangible results, ensuring your brand not only resonates but leaves an indelible mark on your audience and industry. Choose BuzzMagnet for unparalleled PR excellence.
                        </p>

                        {/* <div className='md:w-40' data-aos='fade-left'>
                            <Button variant="contained" sx={buttonStyle} >
                                <Link to='contact' spy={true} smooth={true} offset={-30} duration={200} >Get Started</Link>
                            </Button>
                        </div> */}
                    </div>
                    <div className='w-full'>
                        {/* <CareerCarousel /> */}
                        <img src='/image4.jpeg' alt='img' />
                    </div>
                </div>
            </div>

            <div id='about' className='w-full mt-20'>
                <div className='w-full relative h-[70vh] md:h-[52vh] flex items-center justify-center text-white bg-black'>
                    <img className='absolute w-[100%] h-[100%] object-cover opacity-30 z-0' src='/about.jpg' alt='about' />
                    <div className='w-[95%] z-30 flex flex-col items-center'>
                        <h1 className='text-5xl md:text-6xl text-center font-bold z-10 text-white' data-aos='fade-up'>About Us</h1>
                        <p className='text-center text-lg md:text-2xl py-5 md:w-[80%]' data-aos='fade-up'>
                            At BuzzMagnet PR Solutions, we are more than just a public relations company; we are your strategic partner in crafting compelling stories and generating the buzz that your brand deserves. We understand that in today's fast-paced, digital world, effective PR is the cornerstone of success, and we're here to help you navigate this dynamic landscape.
                        </p>
                    </div>
                </div>

                <div className='w-full flex flex-col items-center'>
                    <div className='w-[95%] flex flex-wrap md:flex-nowrap justify-between my-5 md:my-20'>
                        <div className='w-full'>
                            <h1 className='text-center md:text-left font-bold text-3xl md:text-5xl py-5 redColor' data-aos='fade-up'>
                                Our Story
                            </h1>

                            <img className='md:hidden mb-3' src='/image6.jpeg' alt='story' />

                            <p className='md:w-[90%] text-xl text-center md:text-left text-gray-600' data-aos='fade-up'>
                                BuzzMagnet PR Solutions was born out of a passion for shaping narratives and building strong, lasting relationships. Over the years, we've evolved into a leading PR agency known for our innovative approach and unwavering commitment to our clients' success.
                            </p>
                            <p className='md:w-[90%] py-2 text-xl text-center md:text-left text-gray-600' data-aos='fade-up'>
                                Our team of dedicated professionals brings a diverse range of experiences, skills, and insights to the table, ensuring that every campaign we undertake is a true collaboration of talents.
                            </p>
                        </div>
                        <div className='hidden md:block w-full'>
                            <img src='/image6.jpeg' alt='story' />
                        </div>
                    </div>

                    <div className='w-[95%] flex flex-wrap md:flex-nowrap justify-between gap-5'>
                        <div className='hidden md:block w-full'>
                            <img src='/image5.jpeg' alt='story' />
                        </div>
                        <div className='w-full'>
                            <h1 className='text-center md:text-left font-bold text-3xl md:text-5xl py-5 redColor' data-aos='fade-up'>
                                Our Mission
                            </h1>

                            <img className='md:hidden mb-3' src='/image5.jpeg' alt='story' />

                            <p className='md:w-[90%] text-xl text-center md:text-left text-gray-600' data-aos='fade-up'>
                                At BuzzMagnet PR Solutions, our mission is to amplify your brand's voice and ensure that your message reaches the right audience.
                            </p>
                            <p className='md:w-[90%] py-2 text-xl text-center md:text-left text-gray-600' data-aos='fade-up'>
                                We believe in the power of storytelling and strategic communication to propel your business to new heights. Whether you're a startup looking to make your mark or an established company aiming to stay relevant, we're here to craft a tailored PR strategy that suits your unique needs.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className='w-full flex flex-col items-center'>
                    <div className='w-[95%] py-10 md:py-20'>
                        <h1 className='redColor text-3xl text-center md:text-5xl font-bold' data-aos='fade-up'>What Sets Us Apart</h1>

                        <div className='w-full mt-10 flex-wrap md:flex-nowrap flex justify-between gap-10 md:gap-5' data-aos='fade-left'>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Innovation</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    We stay ahead of the curve when it comes to PR strategies, constantly adapting to the ever-changing media landscape.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Personalized Approach</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    We don&apos;t believe in one-size-fits-all solutions. We take the time to understand your brand, your goals, and your audience, and then tailor our approach accordingly.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Media Relationships</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    Our team has strong relationships with journalists, influencers, and media outlets, giving us the edge when it comes to securing meaningful coverage for your brand.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Transparency</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    We believe in open, honest communication, ensuring you&apos;re always in the know about the progress of your PR campaigns.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='w-[95%] py-10 md:py-10'>
                        <h1 className='redColor text-3xl text-center md:text-5xl font-bold' data-aos='fade-up'>Our Services</h1>

                        <div className='w-full mt-10 flex-wrap md:flex-nowrap flex justify-between gap-10 md:gap-5' data-aos='fade-left'>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Media Relations</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    We have a deep understanding of how the media works and can effectively pitch your story to the right outlets, securing valuable press coverage.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Content Creation</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    Our team of skilled writers can create engaging content, from press releases to thought leadership articles, to help you establish yourself as an industry authority.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Social Media Management</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    We harness the power of social media to build your brand's online presence and engage with your audience effectively.
                                </p>
                            </div>
                            <div className='w-full bg-white border px-6 py-4 rounded-xl shadow-md border-b-4 border-b-[#031927] hover:shadow-2xl hover:border-b-4 hover:border-b-[#F4650E]'>
                                <h2 className='font-bold text-center md:text-start text-xl text-[#031927]'>Crisis Communication</h2>
                                <p className='pt-2 text-center md:text-start text-gray-600'>
                                    In times of crisis, we're there to help you manage your reputation and navigate challenging situations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            {/* <div className='w-full flex justify-center my-10 md:my-20'>
                <div className='w-[95%]'>
                    <h1 className='text-center font-bold text-3xl md:text-5xl pb-10 redColor'>Questions?</h1>
                    <AccordionComponent />
                </div>
            </div> */}

            {/* <ContactComponent /> */}

            <div id='work' className='w-full flex flex-col mt-20 justify-center items-center'>
                <h1 className='text-center text-3xl md:text-5xl font-bold my-10 redColor'>Our Work</h1>
                <div className='w-[95%] flex flex-wrap md:flex-nowrap gap-20 justify-between'>

                    

                    <div className='w-full rounded shadow-md'>
                        <div className='w-full border h-[200px] overflow-hidden'>
                            <img className='w-[100%] h-[100%] object-fill hover:scale-105 duration-100' src='video.ai1.jpeg' alt='workImage' />
                        </div>
                        <div className='px-3 py-2'>
                            <h2 className='font-medium py-2 redColor text-xl'>VDO.AI partners with Zone Media</h2>
                            <p className='text-gray-600 '>VDA.ai and Zone Media collaborate to revolutionize customer engagement through advanced AI solutions. The partnership aims to enhance Zone Media's digital platforms, leveraging VDA.ai's cutting-edge technologies to deliver personalized and efficient user experiences.</p>
                            {
                                vdoAi && <p className='text-gray-600 pt-2'>This strategic alliance underscores a commitment to innovation and elevating customer satisfaction in the rapidly evolving digital landscape.</p>
                            }
                            <button onClick={() => setVdoAi(prev => !prev)} className='my-5 text-blue-600 underline'>
                                {vdoAi ? 'Show less' : 'Read more'}
                            </button>
                        </div>
                    </div>

                    <div className='w-full rounded shadow-md'>
                        <div className='w-full border h-[200px] overflow-hidden'>
                            <img className='w-[100%] h-[100%] object-fill hover:scale-105 duration-100' src='vpXparkAvenue.jpg' alt='workImage' />
                        </div>
                        <div className='px-3 py-2'>
                            <h2 className='font-medium py-2 redColor text-xl'>Viral Pitch X Park Avenue</h2>
                            <p className='text-gray-600 '>Viral Pitch has partnered with Park Avenue from Raymond Consumer Care Ltd to provide influencer marketing services. The collaboration includes launching the #BeerCareForGreatHair campaign for Park Avenue's Beer shampoo range, aiming to enhance brand visibility and recognition on Instagram.</p>
                            {
                                vpParkAvenue && <p className='text-gray-600 pt-2'>Sumit Gupta, Founder of Viral Pitch, expresses pride in assisting such a globally trusted brand. Park Avenue's spokesperson, Pooja Sahgal, highlights the strategic decision to leverage influencer marketing for their Beer Shampoo product range, emphasizing Viral Pitch's storytelling skills and creativity in executing impactful campaigns.</p>
                            }
                            <button onClick={() => setVpParkAvenue(prev => !prev)} className='my-5 text-blue-600 underline'>
                                {vpParkAvenue ? 'Show less' : 'Read more'}
                            </button>
                        </div>
                    </div>
                    

                    <div className='w-full rounded shadow-md'>
                        <div className='w-full border h-[200px] overflow-hidden'>
                            <img className='w-[100%] h-[100%] object-fill hover:scale-105 duration-100' src='zee5_1.jpeg' alt='workImage' />
                        </div>
                        <div className='px-3 py-2'>
                            <h2 className='font-medium py-2 redColor text-xl'>Zee5 X Apex Media</h2>
                            <p className='text-gray-600 '>Zee5's collaboration with Apex Media marks a strategic partnership aimed at expanding the streaming platform's reach and content offerings. The alliance leverages Apex Media's expertise in content creation and distribution, enhancing Zee5's ability to deliver diverse and engaging content to a wider audience,</p>
                            {
                                zee5 && <p className='text-gray-600 pt-2'>ultimately strengthening its position in the competitive streaming industry.</p>
                            }
                            <button onClick={() => setZee5(prev => !prev)} className='my-5 text-blue-600 underline'>
                                {zee5 ? 'Show less' : 'Read more'}
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Home
