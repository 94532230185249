import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Link } from 'react-scroll';

const Navbar = () => {
    const [isNavbar, setIsNavbar] = useState(false);
    const [active, setActive] = useState('')

    const handleNavbar = () => {
        setIsNavbar((prev) => !prev)
    }

    return (
        <>
            <div className='w-full z-50 bg-white sticky top-0 shadow-md flex justify-center'>
                <div className='w-[95%] flex items-center justify-between'>
                    <div>
                        <a href='/'>
                            <img onClick={() => { setActive(''); setIsNavbar(false) }} className='w-28 cursor-pointer' src='/buzz_logo.png' alt='logo' />
                        </a>
                    </div>

                    <div>
                        <ul className='hidden md:flex items-center gap-10 text-xl font-medium'>

                            <Link to='services' spy={true} smooth={true} offset={-50} duration={200} >
                                <li onClick={() => setActive('work')} className={`hover:text-[#F4650E] ${active == 'work' && 'text-[#F4650E] font-semibold'} cursor-pointer py-4`}>
                                    Services
                                </li>
                            </Link>

                            <Link to='work' spy={true} smooth={true} offset={-100} duration={200} >
                                <li onClick={() => setActive('expertise')} className={`hover:text-[#F4650E] ${active ==
                                    'expertise' && 'text-[#F4650E] font-semibold'} cursor-pointer py-4`}>
                                    Our Work
                                </li>
                            </Link>

                            <Link to='contact' spy={true} smooth={true} offset={100} duration={200} >
                                <li onClick={() => setActive('contact')} className={`hover:bg-[#F4650E] hover:text-white ${active == 'contact' && 'text-[#F4650E] font-semibold'} cursor-pointer py-1.5 px-3 rounded redColor border border-[#F4650E]`}>
                                    Connect with Us
                                </li>
                            </Link>

                            {/* <Link to='about' spy={true} smooth={true} offset={-60} duration={200} >
                                <li onClick={() => setActive('about')} className={`hover:text-[#F4650E] ${active == 'about' && 'text-[#F4650E] font-semibold'} cursor-pointer py-4`}>About</li>
                            </Link>

                            <Link to='contact' spy={true} smooth={true} offset={-30} duration={200} >
                                <li onClick={() => setActive('contact')} className={`hover:text-[#F4650E] ${active == 'contact' && 'text-[#F4650E] font-semibold'} cursor-pointer py-4`}> Contact </li>
                            </Link> */}
                        </ul>

                        {
                            isNavbar ?
                                <CloseIcon onClick={handleNavbar} sx={{ display: { xs: 'block', md: 'none', lg: 'none' }, fontSize: '28px' }} />
                                :
                                <MenuIcon onClick={handleNavbar} sx={{ display: { xs: 'block', md: 'none', lg: 'none' }, fontSize: '28px' }} />
                        }

                    </div>
                </div>
            </div>

            {
                isNavbar &&
                <div className='w-full h-screen fixed top-[75px] text-white font-medium text-xl bg-[#031927] z-50'>
                    <ul className='p-5 flex flex-col gap-8 mt-5'>

                        <Link to='our-expertise' spy={true} smooth={true} offset={-100} duration={200} >
                            <li onClick={() => { setActive('expertise'); setIsNavbar(false) }} className={`hover:text-[#F4650E] ${active == 'expertise' && 'text-[#F4650E] font-semibold'} cursor-pointer`}>
                                <span><RadioButtonUncheckedIcon sx={{ width: '12px', mr: '10px' }} /></span> Services
                            </li>
                        </Link>
                        <Link to='work' spy={true} smooth={true} offset={-100} duration={200} >
                            <li onClick={() => { setActive('work'); setIsNavbar(false) }} className={`hover:text-[#F4650E] ${active == 'work' && 'text-[#F4650E] font-semibold'} cursor-pointer`}>
                                <span><RadioButtonUncheckedIcon sx={{ width: '12px', mr: '10px' }} /></span> Our Work
                            </li>
                        </Link>
                        <Link to='contact' spy={true} smooth={true} offset={100} duration={200} >
                            <li onClick={() => {setActive('contact'); setIsNavbar(false)}} className={`hover:bg-[#F4650E] hover:text-white ${active == 'contact' && 'text-[#F4650E] font-semibold'} cursor-pointer py-1.5 px-3 text-center rounded redColor border border-[#F4650E]`}>
                                Connect with Us
                            </li>
                        </Link>
                    </ul>


                    <div className="w-full absolute flex flex-col items-center bottom-24 sm:flex sm:items-center sm:justify-between">
                        <Footer.Copyright
                            by="BuzzMagnet"
                            href="#"
                            year={2023}
                        />
                        <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                            <Footer.Icon
                                href="#"
                                icon={BsFacebook}
                            />
                            <Footer.Icon
                                href="#"
                                icon={BsInstagram}
                            />
                            <Footer.Icon
                                href="#"
                                icon={BsTwitter}
                            />
                            <Footer.Icon
                                href="#"
                                icon={BsGithub}
                            />
                            <Footer.Icon
                                href="#"
                                icon={BsDribbble}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Navbar
