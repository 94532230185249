import React from 'react'
import { Link } from 'react-router-dom'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs';

const FooterComponent1 = () => {
    return (
        <>
            <div id='contact' className='w-full mt-20 py-4 redBg text-white flex justify-center'>


                <div className='w-[95%] flex flex-wrap md:flex-nowrap md:items-center justify-center md:justify-between'>
                    <div className='w-full flex justify-center md:w-auto'>
                        <Link to='/privacyPolicy'>
                            <span className='underline'>Privacy Policy</span> &nbsp;
                        </Link>
                        <Link to='/terms&condition'>
                            <span className='underline'>Terms & Condition</span>
                        </Link>
                    </div>
                    <div className='w-full flex justify-center my-5 md:my-0 md:w-auto'>
                        <span className='text-center' > &copy; BuzzMagnet | Plot No. - 127, Sector 44, Gurugram, Haryana 122003 | team@buzzmagnet.in</span>
                    </div>
                    {/* <div className='w-full flex justify-center md:w-auto gap-5'>
                        <Link to=''>
                            <BsFacebook className='text-3xl'/>
                        </Link>
                        <Link to=''>
                            <BsInstagram className='text-3xl'/>
                        </Link>
                        <Link to=''>
                            <BsTwitter className='text-3xl'/>
                        </Link>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default FooterComponent1
