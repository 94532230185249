import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import Home from './components/landingpage/Home';
import FooterComponent from './components/footer/FooterComponent';
import FooterComponent1 from './components/footer/FooterComponent1';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermAndCondition from './components/T&C/TermAndCondition';
// import Career from './components/career/Career';
// import AboutComponent from './components/about/AboutComponent';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/privacyPolicy' exact element={<PrivacyPolicy/>} />
        <Route path='/terms&condition' exact element={<TermAndCondition />} />
      </Routes>
      <FooterComponent1/>
    </BrowserRouter>
  );
}

export default App;
