import React from 'react'

const TermAndCondition = () => {
  return (
    <div className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-4 redColor">Terms and Conditions</h1>

      <p className="mb-4">
        Welcome to BuzzMagnet PR Agency! By using our services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before engaging with our agency.
      </p>

      {/* <p className="mb-4">
        The use of this website is subject to the following terms of use:
      </p>

      <ol className="list-decimal pl-8 mb-4">
        <li>Your use of any information or materials on this website is entirely at your own risk.</li>
        <li>This website contains material which is owned by or licensed to us.</li>
        <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
        <li>This website may also include links to other websites. These links are provided for your convenience.</li>
        <li>Your use of this website and any dispute arising out of such use is subject to the laws of [Your Country].</li>
      </ol> */}

      <h2 className="text-2xl font-bold mb-2 redColor">1. Services</h2>

      <h3 className="font-bold mb-2 redColor">1.1 Scope:</h3>
      <p className="mb-4">
        BuzzMagnet PR Agency provides public relations services to clients. The specific details of services, deliverables, and timelines will be outlined in a separate agreement between BuzzMagnet and the client.
      </p>
      <h3 className="font-bold mb-2 redColor">1.2 Modifications:</h3>
      <p className="mb-4">
        BuzzMagnet reserves the right to modify, suspend, or discontinue any aspect of its services at any time. Clients will be notified in advance of any significant changes to the agreed-upon services.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">2. Client Responsibilities</h2>
      <h3 className="font-bold mb-2 redColor">2.1 Cooperation:</h3>
      <p className="mb-4">
        Clients agree to cooperate with BuzzMagnet in the delivery of services. This includes providing necessary information, approvals, and feedback in a timely manner.
      </p>
      <h3 className="font-bold mb-2 redColor">2.2 Payment:</h3>
      <p className="mb-4">
        Clients are responsible for the payment of agreed-upon fees as outlined in the service agreement. Payments are due according to the terms specified in the invoice.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">3. Intellectual Property</h2>
      <h3 className="font-bold mb-2 redColor">3.1 Ownership:</h3>
      <p className="mb-4">
        Any intellectual property created as a result of the services provided by BuzzMagnet, including but not limited to branding, content, and strategies, shall remain the property of BuzzMagnet unless otherwise specified in writing.
      </p>
      <h3 className="font-bold mb-2 redColor">3.2 Use of Deliverables:</h3>
      <p className="mb-4">
        Clients are granted a non-exclusive, non-transferable license to use the deliverables provided by BuzzMagnet for the intended purpose outlined in the service agreement.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">4. Confidentiality</h2>
      <h3 className="font-bold mb-2 redColor">4.1 Non-Disclosure:</h3>
      <p className="mb-4">
      Both parties agree to keep confidential all information shared during the course of the engagement that is not public knowledge.
      </p>
      <h3 className="font-bold mb-2 redColor">4.2 Exceptions:</h3>
      <p className="mb-4">
      Confidentiality does not apply to information that is publicly available, independently developed by either party, or required to be disclosed by law.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">5. Limitation of Liability</h2>
      <h3 className="font-bold mb-2 redColor">5.1 Indemnification:</h3>
      <p className="mb-4">
      Clients agree to indemnify and hold BuzzMagnet, its employees, and agents harmless from any claims, losses, or damages arising from the client's use of the services.
      </p>
      <h3 className="font-bold mb-2 redColor">5.2 Limitation:</h3>
      <p className="mb-4">
      In no event shall BuzzMagnet be liable for any indirect, consequential, or incidental damages arising out of or related to the services.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">6. Governing Law</h2>
      
      <p className="mb-4">
      These terms and conditions shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any disputes arising out of these terms shall be resolved through negotiation and, if necessary, through the appropriate legal channels.
      </p>


      <h2 className="text-2xl font-bold mb-2 redColor">7. Termination</h2>
      
      <p className="mb-4">
      Either party may terminate the engagement with written notice if the other party breaches a material term of these terms and conditions.
      </p>

      <h2 className="text-2xl font-bold mb-2 redColor">Contact Information</h2>
      <p className="mb-4">
      For any questions or concerns regarding these terms and conditions, please contact us at contact@buzzmagnet.com.
        {/* <a href="mailto:info@example.com" className="text-blue-500">
        contact@buzzmagnet.com.
        </a> */}
        
      </p>

      <p className="mb-4">
      By using our services, you acknowledge that you have read, understood, and agreed to these terms and conditions. BuzzMagnet PR Agency reserves the right to update these terms periodically, and continued use of our services constitutes acceptance of the revised terms and conditions.
      </p>
    </div>
  )
}

export default TermAndCondition
