import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
            <h1 className="text-4xl font-bold mb-4 redColor">Privacy Policy</h1>

            <p className="mb-4">
                At BuzzMagnet PR Agency, we are committed to protecting the privacy and security of the personal information of our clients, employees, and website visitors. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of personal information. By using our services or accessing our website, you consent to the terms of this Privacy Policy.
            </p>

            {/* <p className="mb-4">
        The use of this website is subject to the following terms of use:
      </p>

      <ol className="list-decimal pl-8 mb-4">
        <li>Your use of any information or materials on this website is entirely at your own risk.</li>
        <li>This website contains material which is owned by or licensed to us.</li>
        <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
        <li>This website may also include links to other websites. These links are provided for your convenience.</li>
        <li>Your use of this website and any dispute arising out of such use is subject to the laws of [Your Country].</li>
      </ol> */}

            <h2 className="text-2xl font-bold mb-2 redColor">1. Information We Collect</h2>

            <h3 className="font-bold mb-2 redColor">1.1 Client Information:</h3>
            <p className="mb-4">
                We collect personal information from our clients, including but not limited to names, contact information, and other relevant details necessary for providing our public relations services.
            </p>
            <h3 className="font-bold mb-2 redColor">1.2 Employee Information:</h3>
            <p className="mb-4">
                For employment purposes, we collect personal information from our employees, such as contact details, employment history, and other relevant information required for managing employment relationships.
            </p>
            <h3 className="font-bold mb-2 redColor">1.3 Website Visitors:</h3>
            <p className="mb-4">
                When you visit our website, we may collect non-personal information, such as your IP address, browser type, and browsing patterns, to enhance the functionality and user experience of our site.
            </p>

            <h2 className="text-2xl font-bold mb-2 redColor">2. How We Use Information</h2>
            <h3 className="font-bold mb-2 redColor">2.1 Client and Employee Information:</h3>
            <p className="mb-4">
                We use personal information to provide our public relations services, manage client relationships, and facilitate employment-related processes. This includes communication, project management, and invoicing.
            </p>
            <h3 className="font-bold mb-2 redColor">2.2 Website Visitors:</h3>
            <p className="mb-4">
                Non-personal information collected from website visitors is used for analytics and to improve the performance and content of our website. We do not use this information to identify individuals.
            </p>


            <h2 className="text-2xl font-bold mb-2 redColor">3. Information Sharing</h2>

            <p className="mb-4">
                We do not sell or disclose personal information to third parties, except as required by law or with explicit consent from the individual.
            </p>


            <h2 className="text-2xl font-bold mb-2 redColor">4. Data Security</h2>

            <p className="mb-4">
                We take appropriate measures to protect personal information from unauthorized access, disclosure, alteration, and destruction. Our security protocols are regularly reviewed and updated to ensure the integrity of the information we handle.
            </p>


            <h2 className="text-2xl font-bold mb-2 redColor">5. Cookies</h2>

            <p className="mb-4">
                Our website may use cookies to enhance user experience. Users can control the use of cookies through their browser settings. However, disabling cookies may affect the functionality of certain features on our website.
            </p>

            <h2 className="text-2xl font-bold mb-2 redColor">6. Third-Party Links</h2>

            <p className="mb-4">
                Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage users to review the privacy policies of any linked sites.
            </p>


            <h2 className="text-2xl font-bold mb-2 redColor">7. Changes to Privacy Policy</h2>

            <p className="mb-4">
                We reserve the right to update this Privacy Policy periodically. Changes will be reflected on our website with an updated effective date. Continued use of our services or website constitutes acceptance of the revised Privacy Policy.
            </p>


            <h2 className="text-2xl font-bold mb-2 redColor">Contact Information</h2>
            <p className="mb-4">
                If you have questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at contact@buzzmagnet.com.
                {/* <a href="mailto:info@example.com" className="text-blue-500">
        contact@buzzmagnet.com.
        </a> */}

            </p>

            <p className="mb-4">
                By using our services or accessing our website, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as described herein.
            </p>
        </div>
    )
}

export default PrivacyPolicy
